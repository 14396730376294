<template>
    <div style="background:rgba(0,0,0,0);width:100vw;height:100vh;padding:6px;">
        <section class="container" v-if="winObj">
            <div class="top-row row align-center space-between">
                <div>
                    <i class="iconfont icon-laba"></i>
                    <span>{{msgData.topic || '总部通知'}}</span>
                </div>
                <span @click="closeWin">
                    <i class="el-icon-close"></i>
                </span>
            </div>
            <div class="message-card">
                <p class="title">{{msgData.title}}</p>
                <div class="content">{{msgData.content}}</div>
                <div v-if="msgData.currentTime" class="finsh-time"><i class="iconfont icon-beifendingshiqi-02"></i>时间：{{msgData.currentTime}}</div>
                <div v-if="msgData.guestBookingOrderNo" @click="openWind" class="show-detail">查看详情</div>
            </div>
        </section>
    </div>
</template>

<script>
import { ipcApiRoute } from '@/api/main'
import { ipc } from '@/utils/ipcRenderer'
export default {
    name: 'Message',
    data(){
        return{
            msgData: null,
			winObj: null
        }
    },
	created() {
		this.winObj = JSON.parse(this.$common.getUrlParams('params'))
		this.msgData = this.winObj
	},
    methods: {
        closeWin(){
			ipc.invoke(ipcApiRoute.closeWindow, this.winObj.winId).then(r => {})
        },
        openWind(){
          if(this.msgData.guestBookingOrderNo){
            let args = {
              title: '客订记录',
              route: '/customerOrder',
              winId: 'customerOrder'
            }
          }
        }
    }
}
</script>

<style lang="scss" scoped>
.container{
    width:calc(100vw - 12px);
    height:calc(100vh - 12px);
    overflow: hidden;
    background: #fff;
    position: relative;
    animation: focusinputitem 0.8s infinite;
    -webkit-animation: focusinputitem 0.8s infinite;
}
@keyframes focusinputitem {
    from {
      box-shadow: 0 0 7px 2px rgba(197, 98, 9, 1);
    }
    to {
      box-shadow: 0 1px 0 0 rgb(255, 255, 255);
    }
}

@-webkit-keyframes focusinputitem /*Safari and Chrome*/ {
    from {
      box-shadow: 0 0 7px 2px rgba(197, 98, 9, 1);
    }
    to {
      box-shadow: 0 1px 0 0 rgb(255, 255, 255);
    }
}

.top-row{
    height:40px;
    padding:0 10px;
    background:#067CF2;
    color:#fff;
    div i{
        font-size: 16px;
        margin-right: 5px;
    }
    span{
        cursor: pointer;
        i{
            font-size: 20px;
        }
    }
}
.message-card{
    margin: 10px;
    overflow-y: scroll;
    .title{
        margin-top: 5px;
        font-size:20px;
        color: #000;
        font-family: 'font-Bold';
        font-weight: bold;
    }
    .content{
        font-size:15px;
        margin-top:10px;
        color:#666;
        height: 210px;
        line-height: 24px;
    }
    .finsh-time{
        color: #000;
        font-size: 14px;
        margin-top: 10px;
        i{
            margin-right: 5px;
            float: left;
            margin-top: 2px;
        }
    }
    .show-detail{
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        background: #F9F9F9;
        border: 1px solid #D3D3D3;
        border-bottom: none;
        color: #067CF2;
        text-align: center;
        line-height: 45px;
        font-family: 'font-Bold';
        cursor: pointer;
        font-size: 18px;
    }
}
</style>
